import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { browserTracingIntegration, init, replayIntegration } from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

//Sentry was initialized using this guide:
//https://docs.sentry.io/platforms/javascript/guides/angular/
if (environment.name !== 'LOCAL') {
  init({
    dsn: 'https://6c58be80e268a97e3c1bb28bee1db4d8@o430870.ingest.sentry.io/4505630255349760',
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https?:\/\/.*\.garmin\.com/],

    // Capture Replay for 0% of all sessions,
    replaysSessionSampleRate: 0.0,
    // plus for 100% of sessions with an error
    replaysOnErrorSampleRate: 1.0,
    environment: environment.name
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch((err) => console.error(err));
